var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.formTitle)+" ")]),_c('v-card-subtitle',[_vm._t("card-subtitle")],2),_c('v-card-text',{staticClass:"mt-5"},[_c('v-form',{ref:"form"},[_c('v-text-field',{attrs:{"label":"Name","outlined":!_vm.preview,"disabled":_vm.isLoading,"readonly":_vm.preview,"loading":_vm.isMarketLoading,"rules":[
          function (v) { return !!v || _vm.$t('VALIDATION.COMMON.FIELD_REQUIRED'); },
          _vm.rules.cantBeSpacesOnly
        ]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),(_vm.preview)?_c('v-text-field',{attrs:{"value":_vm.previewLanguage,"label":"Language","readonly":_vm.preview}}):_c('v-autocomplete',{attrs:{"label":"Language","outlined":!_vm.preview,"disabled":_vm.isLoading,"loading":_vm.isMarketLoading,"items":_vm.languages,"item-text":"label","rules":[function (v) { return !!v || _vm.$t('VALIDATION.COMMON.FIELD_REQUIRED'); }]},model:{value:(_vm.form.language),callback:function ($$v) {_vm.$set(_vm.form, "language", $$v)},expression:"form.language"}}),(_vm.preview)?_c('v-text-field',{attrs:{"value":_vm.previewCountry,"label":"Country","readonly":_vm.preview}}):_c('v-autocomplete',{attrs:{"label":"Country","outlined":!_vm.preview,"disabled":_vm.isLoading,"readonly":_vm.preview,"loading":_vm.isMarketLoading,"items":_vm.countries,"item-text":"label","item-value":"alpha2","rules":[function (v) { return !!v || _vm.$t('VALIDATION.COMMON.FIELD_REQUIRED'); }]},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}}),_vm._v(" API Tokens "),_c('v-divider',{staticClass:"mb-5 mt-1"}),_c('v-text-field',{attrs:{"label":"Brandwatch","outlined":!_vm.preview,"disabled":_vm.isLoading,"readonly":_vm.preview,"loading":_vm.isMarketLoading,"rules":[
          function (v) { return !!v || _vm.$t('VALIDATION.COMMON.FIELD_REQUIRED'); },
          _vm.rules.cantBeSpacesOnly
        ]},model:{value:(_vm.form.api_tokens.brandwatch),callback:function ($$v) {_vm.$set(_vm.form.api_tokens, "brandwatch", $$v)},expression:"form.api_tokens.brandwatch"}}),_c('v-text-field',{attrs:{"label":"Similar web","outlined":!_vm.preview,"disabled":_vm.isLoading,"readonly":_vm.preview,"loading":_vm.isMarketLoading,"rules":[
          function (v) { return !!v || _vm.$t('VALIDATION.COMMON.FIELD_REQUIRED'); },
          _vm.rules.cantBeSpacesOnly
        ]},model:{value:(_vm.form.api_tokens.similarweb),callback:function ($$v) {_vm.$set(_vm.form.api_tokens, "similarweb", $$v)},expression:"form.api_tokens.similarweb"}}),_c('v-text-field',{attrs:{"label":"Pulsar","outlined":!_vm.preview,"disabled":_vm.isLoading,"readonly":_vm.preview,"loading":_vm.isMarketLoading,"rules":[
          function (v) { return !!v || _vm.$t('VALIDATION.COMMON.FIELD_REQUIRED'); },
          _vm.rules.cantBeSpacesOnly
        ]},model:{value:(_vm.form.api_tokens.pulsar),callback:function ($$v) {_vm.$set(_vm.form.api_tokens, "pulsar", $$v)},expression:"form.api_tokens.pulsar"}})],1),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"secondary","outlined":""},on:{"click":_vm.handleCancel}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.preview},on:{"click":_vm.handleSubmit}},[_vm._v(_vm._s(_vm.submitButtonText))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }