<template>
  <v-card>
    <v-card-title>
      {{ formTitle }}
    </v-card-title>
    <v-card-subtitle>
      <slot name="card-subtitle"></slot>
    </v-card-subtitle>
    <v-card-text class="mt-5">
      <v-form ref="form">
        <v-text-field
          v-model="form.name"
          label="Name"
          :outlined="!preview"
          :disabled="isLoading"
          :readonly="preview"
          :loading="isMarketLoading"
          :rules="[
            v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED'),
            rules.cantBeSpacesOnly
          ]"
        />
        <v-text-field
          v-if="preview"
          :value="previewLanguage"
          label="Language"
          :readonly="preview"
        />
        <v-autocomplete
          v-else
          v-model="form.language"
          label="Language"
          :outlined="!preview"
          :disabled="isLoading"
          :loading="isMarketLoading"
          :items="languages"
          item-text="label"
          :rules="[v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED')]"
        />
        <v-text-field
          v-if="preview"
          :value="previewCountry"
          label="Country"
          :readonly="preview"
        />
        <v-autocomplete
          v-else
          v-model="form.country"
          label="Country"
          :outlined="!preview"
          :disabled="isLoading"
          :readonly="preview"
          :loading="isMarketLoading"
          :items="countries"
          item-text="label"
          item-value="alpha2"
          :rules="[v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED')]"
        />
        API Tokens
        <v-divider class="mb-5 mt-1" />
        <v-text-field
          v-model="form.api_tokens.brandwatch"
          label="Brandwatch"
          :outlined="!preview"
          :disabled="isLoading"
          :readonly="preview"
          :loading="isMarketLoading"
          :rules="[
            v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED'),
            rules.cantBeSpacesOnly
          ]"
        />
        <v-text-field
          v-model="form.api_tokens.similarweb"
          label="Similar web"
          :outlined="!preview"
          :disabled="isLoading"
          :readonly="preview"
          :loading="isMarketLoading"
          :rules="[
            v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED'),
            rules.cantBeSpacesOnly
          ]"
        />
        <v-text-field
          v-model="form.api_tokens.pulsar"
          label="Pulsar"
          :outlined="!preview"
          :disabled="isLoading"
          :readonly="preview"
          :loading="isMarketLoading"
          :rules="[
            v => !!v || $t('VALIDATION.COMMON.FIELD_REQUIRED'),
            rules.cantBeSpacesOnly
          ]"
        />
      </v-form>

      <v-row>
        <v-spacer />
        <v-col class="text-right">
          <v-btn class="mr-2" color="secondary" outlined @click="handleCancel"
            >Cancel</v-btn
          >
          <v-btn color="primary" @click="handleSubmit" :disabled="preview">{{
            submitButtonText
          }}</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
import { cantBeSpacesOnly } from "@/validators";

export default {
  name: "MarketForm",
  props: {
    formTitle: {
      type: String,
      default: "Create new Market"
    },
    preview: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isMarketLoading: {
      type: Boolean,
      default: false
    },
    market: {
      type: [Object, null]
    },
    submitButtonText: {
      type: String,
      default: "Create"
    }
  },
  data() {
    return {
      rules: {
        cantBeSpacesOnly
      },
      form: {
        country: null,
        name: null,
        language: null,
        api_tokens: {
          brandwatch: null,
          similarweb: null,
          pulsar: null
        }
      }
    };
  },
  computed: {
    ...mapState({
      countries: state => state.api.misc.countries,
      languages: state => state.api.misc.languages
    }),
    previewLanguage() {
      const lang = this.languages.find(
        item => item.value === this.form.language
      );
      let label = "error";

      if (lang) {
        label = lang.label;
      }

      return label;
    },
    previewCountry() {
      const country = this.countries.find(
        item => item.alpha2 === this.form.country
      );
      let label = "error";

      if (country) {
        label = country.label;
      }

      return label;
    }
  },
  watch: {
    market: {
      immediate: true,
      deep: true,
      handler() {
        this.setMarketForm();
      }
    }
  },
  methods: {
    setMarketForm() {
      if (this.market) {
        Object.keys(this.form).forEach(key => {
          if (key === "api_tokens") {
            Object.keys(this.form.api_tokens).forEach(tokenKey => {
              this.form.api_tokens[tokenKey] = this.market.api_tokens[tokenKey];
            });
          } else {
            this.form[key] = this.market[key];
          }
        });
      }
    },
    handleCancel() {
      this.$router.push({ name: "PanelMarkets" });
    },
    handleSubmit() {
      if (this.$refs.form.validate()) {
        this.$emit("submit", this.form);
      }
    }
  }
};
</script>
